<!-- 家校互联-首页轮播 -->
<template>
  <div class="center-main">
    <div v-if="!errorShow">
      <loading :loadingShow="loadingShow"></loading>
      <div v-if="!loadingShow" class="content-wrapper">
        <div class="swiper-container">
          <!-- 顶部按钮 -->
          <div class="btn">
            <span class="title">最多可上传4张图片</span>
            <el-button-group v-if="!isEdit">
              <xk-button type="primary"  @click="isEdit = true"  v-hasPermi="['banner:save']">添加/修改</xk-button>
            </el-button-group>
            <el-button-group v-else>
              <xk-button type="primary" @click="handleSave">保存</xk-button>
              <xk-button type="info" @click="handleEsc">取消</xk-button>
            </el-button-group>
          </div>
          <div class="swiper">
            <el-empty v-if="fileList.length === 0 && !isEdit" description="暂无轮播图片"></el-empty>
            <!-- 轮播图展示 -->
            <draggable v-else v-model="fileList" :disabled="!isEdit">
              <transition-group class="draggble">
                <div
                  v-for="(item, index) in fileList"
                  :key="item.bannerUrl"
                  class="image-views"
                >
                  <i v-show="isEdit" class="el-icon-error delete" style="z-index: 1" @click="deleteImage(index, item.id)" />
                  <el-image
                    :src="imgUrl(item.bannerUrl)"
                    :preview-src-list="[imgUrl(item.bannerUrl)]"
                    style="width:100%;height: 100%"
                  />
                </div>
              </transition-group>
            </draggable>
            <!-- 上传图片组件 -->
            <el-upload
              ref="upload"
              v-if="isEdit && fileList.length < 4"
              class="upload-demo"
              drag
              :action="action"
              :headers="headers"
              :show-file-list="false"
              :with-credentials="true"
              accept="image/png, image/jpeg, image/jpg"
              :on-change="changeUpload"
              :auto-upload="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip">只能上传的jpg/png/jpeg文件，且不超过10MB</div>
            </el-upload>
          </div>
          <el-dialog title="剪切图片" class="cro-dialog" append-to-body :visible.sync="cropper_visible" width="780px" :close-on-click-modal="false">
            <div class="cropper-content">
              <div class="cropper" style="text-align:center">
                <vue-cropper
                  ref="cropper"
                  :img="option.img"
                  :info="true"
                  :fixed-box="option.fixedBox"
                  :auto-crop-width="option.autoCropWidth"
                  :auto-crop-height="option.autoCropHeight"
                  :can-scale="option.canScale"
                  :auto-crop="option.autoCrop"
                  :info-true="option.infoTrue"
                />
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="text" icon="el-icon-refresh-left" @click="rotateLeft">左旋转</el-button>
                <el-button type="text" icon="el-icon-refresh-right" @click="rotateRight">右旋转</el-button>
                <el-button @click="cropper_visible = false">取 消</el-button>
                <el-button type="primary" @click="finish">上传图片</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
    <error v-else :errorShow="errorShow"></error>
  </div>
</template>

<script>
import {
  // 功能组件
  Error,
  Loading,
  // 工具函数
  throttle
} from 'common-local';
import {getToken} from "@/libs/auth";
import draggable from 'vuedraggable'
import { VueCropper } from 'vue-cropper'

import {
  mapState
} from 'vuex';
// Ajax 请求
import { SystemModel } from '@/models/System.js'
import {uploadAction} from "@/libs/settings";

export default {
  name: "InterconnectionSwiperScrollWrapper",
  components: {
    Error,
    Loading,
    draggable,
    VueCropper
  },
  data() {
    return {
      // 功能组件
      errorShow: false,
      loadingShow: false,
      // 表头
      backgroundHeader: '',
      // 表头字体
      headerColor: '',
      schoolId: '',
      isEdit: false,
      fileList: [],
      cropper_visible: false,
      option: {
        img: '', // 裁剪图片的地址
        file: '',
        info: true, // 裁剪框的大小信息
        fixedBox: true,
        autoCropWidth: 718,
        autoCropHeight: 240,
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        infoTrue: false // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      }
    }
  },
  computed: {
    ...mapState({
      templateInfo: state => state.templateInfo,
    }),
    // upload api
    action() {
      return uploadAction
    },
    // token
    headers() {
      return {
        'Authorization': getToken()
      }
    }
  },
  created() {
    this.schoolId = this.$store.state.schoolId
    this.getImgList()
  },
  methods: {
    imgUrl(url) {
      return url
    },
    // delete image by index
    deleteImage(index, id) {
      if (this.isEdit) {
        this.fileList.splice(index, 1)
      } else{
        this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this._fet('/school/schoolBanner/delete', {bannerListVOList: [{ id: id }]}).then((res) => {
            this.handleRes(res, () => {
              this.$message.success('删除成功')
              this.getImgList()
            })
          })
        }).catch(() => {
        })
      }
    },
    // save image list
    handleSave() {
      const bannerUrl = this.fileList.map(item => item.bannerUrl)
      if (bannerUrl.length > 4) {
        return this.$message.error('上传图片不能超过4张')
      }
      this._fet('/school/schoolBanner/addBanner',{ schoolId: this.schoolId, url: bannerUrl }).then((res) => {
        this.handleRes(res, () => {
          this.$message.success('修改成功')
          this.isEdit = false
          this.getImgList()
        })
      })
    },
    // deal file list
    handleAvatarSuccess(res) {
      if (res.code === '200') {
        this.fileList.push({ bannerUrl: res.data.filePath })
      }
    },
    // get image list
    getImgList() {
      this._fet('/school/schoolBanner/getBanner',{
          schoolId: this.schoolId,
          belongPlat: "2",
      }).then((res) => {
        this.handleRes(res, () => {
          this.fileList = res.data.data
        })
      })
    },
    // 限制文件上传类型及大小
    beforeAvatarUpload(file) {
      const types = ['.jpg','.png','.jpeg']
      const isImage = types.includes(file.name.substring(file.name.lastIndexOf('.')))
      const isLt10M = file.size / 1024 / 1024 < 10
      const is50 = file.name.length < 50
      if (!is50) {
        this.$message.error('上传文件名称不能超过50个字符!');
        return false
      }
      if (!isImage) {
        this.$message.error('上传图片的格式只能是 jpg或png或jpeg 格式!')
        return false
      }
      if (!isLt10M) {
        this.$message.error('上传图片的大小不能超过 10M!')
        return false
      }
    },
    handleEsc() {
      this.isEdit = false
      this.getImgList()
    },
    changeUpload(file) {
        const flag = this.beforeAvatarUpload(file)
        if (flag !== false) {
            this.$nextTick(() => {
                this.cropper_visible = true
                // 上传预览图片  是个临时的缓存地址
                this.option.img = URL.createObjectURL(file.raw)
                this.option.file = file
            })
        }
    },
    finish() {
      this.$refs.cropper.getCropBlob((data) => {
        let newFile = new window.File([data], this.option.file.name, { type: data.type })
        let formData = new FormData()
        formData.append('file', newFile)
        const systemModel = new SystemModel()
        systemModel.uploadFile(formData).then((res) => {
          this.handleRes(res, () => {
            this.fileList.push({ bannerUrl: res.data.data.url })
          })
        })
        this.cropper_visible = false
      })
    },
      //向左旋转
      rotateLeft () {
          this.$refs.cropper.rotateLeft()
      },
      //向右旋转
      rotateRight () {
          this.$refs.cropper.rotateRight()
      },
    // 接口请求结果处理
      handleRes(res, fn) {
          if (res.data.code === '200') {
              fn()
          } else if (res.data && res.data.msg) {
              this.$message.error(res.data.msg)
          }
      },
    initTabColor() {
      this.template_info = this.templateInfo;
      this.template_info.forEach((p, i) => {
        if (p.sel) {
          this.headerColor = p.textColor;
        }
      })
    },
  },
  watch: {
    'templateInfo': {
      handler() {
        this.initTabColor();
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.center-main {
  margin: 10px;
  border-radius: 5px;
  background-color: #fff;
  height: calc(100vh - 130px);
}
.swiper-container {
  margin: 15px;
  .btn {
    text-align: right;
  }
  .title {
    margin-top: 10px;
    font-size: 14px;
    float: left;
  }
  .swiper {
    margin-top: 28px;
    display: inline-block;
    width:100%;
    .draggble {
      display: flex;
      flex-wrap: wrap;
      > * {
        margin-right: 19px;
        margin-bottom: 24px;
      }
    }
    .image-views {
      width: 400px;
      height: 180px;
      position: relative;
      .delete {
        position: absolute;
        right: -10px;
        top: -10px;
        z-index: 9999;
        font-size: 26px;
        color: #909399;
        &:hover {
          transform: scale(1.2, 1.2);
          color: #F56C6C;
        }
      }
    }
  }
  .el-upload__tip {
    margin-top: 20px;
  }
}
.cropper-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .cropper {
        width: 760px;
        height: 300px;
    }
    .vue-cropper {
        background: rgb(155, 152, 152);
    }
}
</style>
