<!-- 智慧安全 > 校园安保 > 接送管理 -->
<template>
    <div class="center-main">
        <div v-if="!errorShow" class="right-panel">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-main" :style="{'margin-top': '0'}">
                <div class="filter-wrap">
                    <ExpandFilter
                    :closeWidth="1190"
                    :formData="formData"
                    label-width="auto"
                    marginBottom="1px"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    @treeClick="filterTreeClick"
                    @treeClear="treeClear"
                >
                </ExpandFilter>
                <div class="button-line" v-hasPermi="['parentAddressBook:add','parentAddressBook:export','parentAddressBook:import']"></div>
                <div slot="dropdown"
                        style="margin-left: 10px;
                        flex: 1;
                        display: flex;">
                        <el-button v-hasPermi="['parentAddressBook:add']" type="primary" @click="handleAdd">添加</el-button>
                        <el-button type="enquiry" @click="handleImport" v-hasPermi="['parentAddressBook:import']">
                            导入
                        </el-button>
                        <el-button type="enquiry" @click="handleExport" v-hasPermi="['parentAddressBook:export']">
                            导出
                        </el-button>

                        <el-dropdown
                            trigger="click"
                            style="margin: 0px 10px"
                            >
                            <el-button
                            v-hasPermi="['parentAddressBook:batch']"
                                type="info">
                                批量操作<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <div>
                                        <el-button
                                            type="text"
                                            class="hover"
                                            plain
                                            size="mini"
                                            @click="openPickUp"
                                        >设为接送人</el-button>
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <div>
                                        <el-button
                                            type="text"
                                            class="hover"
                                            plain
                                            size="mini"
                                            @click="closePickUp"
                                        >取消为接送人</el-button>
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-button type="enquiry" @click="pushNoticeBtn" v-hasPermi="['parentAddressBook:notice']">
                            接送通知设置
                        </el-button>
                    </div>
                </div>

                <table-data
                    v-loading="loadingTable"
                    id="table"
                    ref="table"
                    :config="table_config"
                    :tableData="table_data"
                    :backgroundHeader="backgroundHeader"
                    :headerColor="headerColor"
                    @switchHandler="handleSwitch"
                    @handleSelectionChange="handleSelectionChange"
                >
                    <template v-slot:operation="slotData">
                        <el-button
                            type="text"
                            @click="edit(slotData.data)"
                            v-hasPermi="['parentAddressBook:edit']"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            @click="del(slotData.data)"
                            v-hasPermi="['parentAddressBook:delete']"
                        >
                            删除
                        </el-button>
                    </template>
                </table-data>
                <pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                />
                <dialog-wrapper :dialogObj="dialogObj" @handleClose="handleClose">
                    <el-form class="common-form" :model="ruleForm" :rules="rules" ref="ruleForm" label-position="right" label-width="120px">
                        <el-form-item label="家长姓名" prop="parentName">
                            <el-input class="input-width-medium" v-model="ruleForm.parentName" clearable placeholder="请输入"/>
                        </el-form-item>
                        <el-form-item label="手机号" prop="phone">
                            <el-input class="input-width-medium" v-model="ruleForm.phone" clearable placeholder="请输入"/>
                        </el-form-item>
                        <el-form-item label="家长身份">
                            <el-select
                                v-model="ruleForm.userType"
                                filterable
                                clearable
                                allow-create
                                placeholder="家长身份(可输入其他身份)"
                                class="input-width-medium">
                                <el-option value="父亲" label="父亲" />
                                <el-option value="母亲" label="母亲" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择学生" prop="onlyCode">
                            <select-tree
                                ref="treeRef"
                                class="input-width-medium"
                                :tree-show-data="ruleForm.stuName"
                                :tree-option="treeOption"
                                :rule-form="ruleForm"
                                @treeClick="treeClick"
                                @clearTree="clearTree"
                            />
                        </el-form-item>
                        <el-form-item label="身份证号"  v-if="status">
                            <el-input class="input-width-medium" v-model="ruleForm.parentIdCard" clearable placeholder="请输入"/>
                        </el-form-item>
                        <el-form-item label="身份证号" prop="parentIdCard" v-else>
                            <el-input class="input-width-medium" v-model="ruleForm.parentIdCard" clearable placeholder="请输入"/>
                        </el-form-item>
                        <div class="tac">
                            <el-button type="enquiry" @click="handleClose">取消</el-button>
                            <el-button type="primary" @click="submitForm">确定</el-button>
                        </div>
                    </el-form>
                </dialog-wrapper>
                <!-- 导入 -->
                <dialog-wrapper
                    :dialog-obj="importDialog"
                    @handleClose="handleImportClose"
                >
                    <div class="import-wrap">
                        <div>导入方法</div>
                        <div>
                            <span>1、请 </span>
                            <xk-button
                                type="primary"
                                text
                                margin-left="0"
                                @click="downloadTemplate"
                                >下载模板
                            </xk-button>
                            <span> 按照模板填充数据，上传填好的文件</span>
                        </div>
                        <el-upload
                            ref="upload"
                            :action="action"
                            :headers="headers"
                            :data="{schoolId: schoolId}"
                            :file-list="fileList"
                            accept=".xlsx,.xls"
                            :on-change="handleImportChange"
                            :in-remove="handleImportRemove"
                            :show-file-list="true"
                            :auto-upload="false"
                        >
                            <span>2、</span>
                            <xk-button
                                type="primary"
                                text
                                margin-left="0"
                                >上传文件
                            </xk-button>
                        </el-upload>
                    </div>
                    <div style="text-align: right">
                        <el-button @click="handleImportClose">取消</el-button>
                        <el-button
                            type="primary"
                            :loading="importLoading"
                            @click="handleSubmitImport"
                            >确定</el-button
                        >
                    </div>
                </dialog-wrapper>

                <!-- 推送通知 -->
                <dialog-wrapper
                    :dialog-obj="pushNoticeDialog"
                    @handleClose="pushNoticeClose"
                >
                    <div style="text-align: center;">
                        <el-radio-group v-model="pushNoticeForm.pickNoticeFlag">
                            <el-radio :label="'1'">开启</el-radio>
                            <el-radio :label="'0'">关闭</el-radio>
                        </el-radio-group>
                    </div>
                    <div style="text-align: center;width: 260px;margin: 20px 0px; font-size: 14px;" >
                        开启后，学生请假表单审批通过后，给家长发送短信通知
                    </div>
                    <div style="text-align: center">
                        <el-button @click="pushNoticeClose">取消</el-button>
                        <el-button
                            type="primary"
                            @click="updateMessage"
                            >确定</el-button
                        >
                    </div>
                </dialog-wrapper>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    FilterData,
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
    // 工具函数
    debounce
} from 'common-local';
import TableData from "@/components/scrollWrapper/Sub/TableData"
import {
    mapState
} from 'vuex';

// 自定义颜色
import upload from './Summary/upload.vue'
import { downloadFile, formatTreeData, hasPermission } from "@/libs/utils";
import ExpandFilter from "./Sub/ExpandFilter";
import {CampusSafetyModel} from "@/models/CampusSafety";
import SelectTree from "./Sub/selectTree";
import {getToken} from "@/libs/auth";
import { Desensitization } from '@/libs/desensitization.js'
import onResize from "@/mixins/onResize";

export default {
    name: "InterconnectionParentDirectoryScroll",
    mixins: [onResize],
    components: {
        SelectTree,
        FilterData,
        TableData,
        DialogWrapper,
        // 功能组件
        Error,
        Loading,
        Pagination,
        upload,
        ExpandFilter,
        CampusSafetyModel
    },
    data() {
        return {
            // --------- 导入功能 ----------
            // 导入功能
            importDialog: {
                title: "导入",
                dialogVisible: false,
                width: "auto",
            },
            fileList: [],
            importLoading: false, // 导入按钮加载状态
            // -----------------------------

            // ----------- 推送通知 -----------
            pushNoticeDialog: {
                title: "接送通知设置",
                dialogVisible: false,
                width: "auto",
            },
            // 推送通知
            pushNoticeForm:{
                pickNoticeFlag: "0",
                id: "",
                schoolId: ""
            },

            // -------------------------------

            listQuery: {
                //id: '',
                stuName: '',
                schoolId: '',
                classesId: '',
                classesName: '',
                pageNum: 1,
                pageRow: 20,
                pickUp: '',
                studyNo: '',
                parentIdCard: '',
                phone: '',
                userType: '',
                onlyCode:"",
            },
            total: 0,
            // 头部筛选
            formData: {
                btnFormType: true,
                data: [
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '学生姓名',
                        key: 'stuName'
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '唯一号',
                        key: 'onlyCode'
                    },
                    {
                        type: 'treeSelect',
                        label: '',
                        value: '',
                        placeholder: '学生班级',
                        key: 'classesName',
                        list: [],
                        defaultProps: {
                            children: 'child',
                            label: 'name'
                        },
                        expandAll: false,
                        auto: true
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '家长身份',
                        key: 'userType',
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '手机号',
                        key: 'phone',
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '身份证号',
                        key: 'parentIdCard',
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '接送人',
                        key: 'pickUp',
                        list: [
                            {label: '是', value: '1'},
                            {label: '否', value: '2'}
                        ]
                    },
                ],
                btnList: [{
                    type: "primary",
                    text: '查询',
                    fn: 'primary',
                    auth:['parentAddressBook:list']
                },
                    {
                        type: "enquiry",
                        text: '重置',
                        fn: 'reset'
                    },
                    // {
                    //     type: "primary",
                    //     text: '添加',
                    //     fn: 'add',
                    //     auth:['parentAddressBook:add']
                    // }
                ]
            },

            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "学生姓名",
                        prop: "stuName",
                        align: 'center'
                    },
                    {
                        label: "学生班级",
                        prop: "stuClass",
                        align: 'center'
                    },
                    {
                        label: "唯一号",
                        prop: "onlyCode",
                        align: 'center',
                        type:'popoverText',
                    },
                    {
                        label: "家长姓名",
                        prop: "parentName",
                        type:'popoverText',
                        align: 'center'
                    },
                    {
                        label: "手机号",
                        prop: "parentPhone",
                        labelDescription:'家长手机号',
                        align: 'center'
                    },
                    {
                        label: "家长身份",
                        prop: "userType",
                        align: 'center'
                    },
                    {
                        label: "身份证号",
                        labelDescription:'家长身份证号',
                        prop: "parentIdCard",
                        type:'popoverText',
                        align: 'center'
                    },
                    {
                        label: "接送人",
                        labelDescription:'是否接送人',
                        prop: "status",
                        type: 'switch',
                        disabled: !hasPermission(['parentAddressBook:edit']),
                        align: 'center'
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: 'operation',
                        align: 'center',
                        className: 'text-spacing-reduction'
                    }
                ],
                check: true,
                height: ''
            },
            // 表格数据
            table_data: [],
            tableListSel:[],
			// 家长手机号
			parentsPhone: "",
            // 弹出框配置
            dialogObj: {
                title: '添加',
                dialogVisible: false,
                width: 'auto'
            },
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,

            template_info: [],
            // 表头
            backgroundHeader: '#FAFBFC',
            // 表头字体
            headerColor: '#595959',
            // 新增
            ruleForm: {
                id: '',
                parentId: '',
                schoolId: '',
                parentName: '',
                phone: '',
                userType: '',
                onlyCode: '',
                stuName: '',
                parentIdCard: '',
				pickUp: ''
            },
            rules: {
                parentName: [{
                    required: true,
                    message: '请输入家长姓名',
                    trigger: 'blur'
                }],
                phone: [{
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur'
                }, {
                    validator: (rule, value, callback) => {
                        if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)) {
                            callback('手机号不正确');
                        } else {
                            callback();
                        }
                    }, trigger: 'blur'
                }],
                userType: [{
                    required: true,
                    message: '请输入家长身份',
                    trigger: 'change'
                }],
                onlyCode: [{
                    required: true,
                    message: '请选择学生',
                    trigger: 'change'
                }],
                parentIdCard: [{
                    required: true,
                    message: '请输入身份证号',
                    trigger: 'blur'
                }, {
                    validator: (rule, value, callback) => {
                        if (!/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value)) {
                            callback('身份证号不正确');
                        } else {
                            callback();
                        }
                    }, trigger: 'blur'
                }],
            },
            // 下拉树组件配置
            treeOption: {
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                treeData: [],  // 展示数据
                expandAll: false,
            },
            status: true,
        }
    },
    computed: {
        ...mapState({
            templateInfo: state => state.templateInfo,
            schoolId: state => state.schoolId
        }),
        exportUrl() {
            return require('../../assets/images/export.png')
        },
        importUrl() {
            return require('../../assets/images/import.png')
        },
        action() {
            return '/api/parent/schoolInterconnection/upload'
        },
        headers() {
            return {
                'Authorization': getToken()
            }
        },
    },
    created() {
        this.ruleForm.schoolId = this.listQuery.schoolId = this.schoolId
        this.getList();
        this.getTreeStudentData()
        this.getTreeOrganData()
    },
    mounted() {
    },
    methods: {
        // 表格选中数据
         // 选中
         handleSelectionChange(data) {
            this.tableListSel = data;
        },
        // 设为接送人按钮
        openPickUp(){
            if (this.tableListSel.length === 0) {
                this.$message.warning('未选择内容')
                return
            }
            const campusSafety = new CampusSafetyModel();

            let obj = {
                ids: [],
                pickUp: "1"
            }

            this.tableListSel.forEach((item) => {
                obj.ids.push(item.id)
            })

            campusSafety.setBatchSettingPickUp(obj).then((res) => {
                if (res.data.code === '200') {
                    // this.$message.success('设为接送人成功')
                } else {
                    this.$message.error(res.data.msg)
                }
                this.getList();
            })
        },
        // 取消设为接送人按钮
        closePickUp(){
            if (this.tableListSel.length === 0) {
                this.$message.warning('未选择内容')
                return
            }
            const campusSafety = new CampusSafetyModel();

            let obj = {
                ids: [],
                pickUp: "2"
            }
            this.tableListSel.forEach((item) => {
                obj.ids.push(item.id)
            })
            campusSafety.setBatchSettingPickUp(obj).then((res) => {
                if (res.data.code === '200') {
                    // this.$message.success('取消为接送人成功')
                } else {
                    this.$message.error(res.data.msg)
                }
                this.getList();
            })

        },
        // 获取推送通知
        getPushNotice() {
            return new Promise((resolve) => {
                this.pushNoticeForm = {
                    pickNoticeFlag: "0",
                    id: "",
                    schoolId: this.schoolId
                }
                const campusSafety = new CampusSafetyModel();
                campusSafety.getPushNotification({schoolId: this.schoolId}).then((res) => {
                    if (res.data && res.data.code === '200') {
                        let data = res.data.data;
                        this.pushNoticeForm.pickNoticeFlag = data.pickNoticeFlag
                        this.pushNoticeForm.id = data.id
                    } else {
                        this.$message.error(res.data.msg)
                    }
                    resolve(res)
                })
            })
        },
        updateMessage(){
            const campusSafety = new CampusSafetyModel();
            campusSafety.updatePushNotification(this.pushNoticeForm).then((res) => {
                if (res.data.code === '200') {
                    this.$message.success('修改成功')
                }

                this.pushNoticeClose();
            })
        },
        getList(t) {
            this.loadingTable = true
            if (t === 1) {
                this.listQuery.pageNum =1
            }
            const campusSafety = new CampusSafetyModel()
			const desensitization = new Desensitization()
            campusSafety.getParentDirectoryList(this.listQuery).then((res) => {
				if(res.data.code === "200") {
                    let dataList = res.data.data.list;
					this.loadingTable = false
					res.data.data.list.forEach((item) => {
					    item.status = item.pickUp === '1' ? '1' : '0'

					})
                    this.parentsPhone = JSON.parse(JSON.stringify(dataList));
					this.total = res.data.data.totalCount
					this.table_data = desensitization.phone(dataList, 'parentPhone')
				}
            })
        },
        getTreeStudentData() {
            const campusSafety = new CampusSafetyModel()
            campusSafety.getIssueScopeByAccess({
                schoolId: this.schoolId,
                applyPerson: '2'
            }).then((res) => {
                this.treeOption.treeData = res.data.data.filter(i => i.organType !== '1')
            })
        },
        getTreeOrganData() {
            const campusSafety = new CampusSafetyModel()
            campusSafety.getSchoolOrgan({
                schoolId: this.schoolId,
            }).then((res) => {
                this.formData.data[2].list = res.data.data
            })
        },
        clearTree() {
            this.ruleForm.onlyCode = ''
            this.ruleForm.stuName = ''
        },
        treeClick(data) {
            if (data.key === 'student') {
                this.ruleForm.onlyCode = data.onlyCode
                this.ruleForm.stuName = data.name
                this.$refs.treeRef.$refs.elSelect.blur()
            }
        },

        // 推送通知弹窗关闭
        pushNoticeClose() {
            this.pushNoticeDialog.dialogVisible = false;
        },
        async pushNoticeBtn() {
            await this.getPushNotice();
            this.pushNoticeDialog.dialogVisible = true;
        },

        // -------------- 导入功能 --------------
        //    导入
        handleImport() {
            this.importDialog.dialogVisible = true;
        },
         // 关闭导入弹窗
         handleImportClose() {
            this.fileList = [];
            this.importDialog.dialogVisible = false;
        },
        // 下载导入模板
        downloadTemplate() {
            // /school/schoolTeacherLeaveRecord/template
            this._get("/school/schoolInterconnection/template").then(
                (res) => {
                    if (res.data.code === "200") {
                        downloadFile(
                            {
                                url: res.data.data,
                                method: "get",
                            },
                            () => {
                                this.$message.success("下载模板成功");
                            },
                            () => {},
                        );
                    }
                },
            );
        },
        // 处理上传文件变化
        handleImportChange(file) {
            // 上传文件前的验证
            const beforeUpload = (file) => {
                // 上传文件前的验证
                const fileType = file.name.substring(
                    file.name.lastIndexOf("."),
                );
                const isXLSXorXLS = [".xlsx", ".xls"].includes(fileType);
                const isShortName = file.name.length < 30;
                const isSizeUnder10M = file.size / 1024 / 1024 < 10;

                if (!isXLSXorXLS) {
                    this.$message.error(`上传文件格式仅支持xlsx和xls!`);
                    return false;
                }

                if (!isShortName) {
                    this.$message.error("上传文件名称不能超过30个字符!");
                    return false;
                }

                if (!isSizeUnder10M) {
                    this.$message.error(`上传文件的大小不能超过10M!`);
                    return false;
                }
            };
            const flag = beforeUpload(file);
            if (flag !== false) {
                this.fileList = [file];
            } else {
                this.fileList = [];
            }
        },
        // 处理上传文件移除
        handleImportRemove() {
            this.fileList = [];
        },
        // 提交导入
        handleSubmitImport() {
            if (this.fileList.length === 0) {
                return this.$message.warning("请上传文件");
            }
            this.importLoading = true;
            let formData = new FormData();
            formData.append("file", this.fileList[0].raw);
            formData.append("schoolId", this.schoolId);
            const campusSafety = new CampusSafetyModel()
            campusSafety
                .uploadLeaveRecord(formData)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.$message.success("导入成功");
                        this.handleImportClose();
                        this.getList();
                    } else if (res.data.code === "20013") {
                        this.downloadErrorList(res.data);
                    } else {
                        this.$message.error("导入失败");
                    }
                })
                .finally(() => {
                    this.importLoading = false;
                });
        },
         // 下载导入错误数据
         downloadErrorList(data) {
            this.$confirm(data.msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                downloadFile(
                    {
                        url: "/parent/schoolInterconnection/onlycodeErrorExport",
                        method: "post",
                        form: {
                            paramData: data.info.paramData,
                        },
                    },
                    () => {},
                    () => {},
                );
            }).catch(() => {
            });
        },
        // --------------------------------------
        /**
         * 功能按钮
         * handleAdd       添加
         * handleExport    导出
         */
        handleAdd() {
            this.status = true
            this.dialogObj.title = '添加'
            this.dialogObj.dialogVisible = true
        },
        downTel() {
            let campusSafety = new CampusSafetyModel()
            campusSafety.downloadParentDirectory().then((res) => {
                this.handleRes(res, () => {
                    downloadFile({
                        url: res.data.data, method: 'get', form: {schoolId: this.schoolId}
                    }, () => {
                    }, () => {

                    })
                })
            })
        },
        handleExport() {
            let obj = {
                schoolId: this.schoolId
            }
            this.formData.data.filter(i => i.value !== '').forEach((item) => {
                if (item.key === 'classesName') {
                    obj.classesId = this.listQuery.classesId
                } else {
                    obj[item.key] = item.value
                }
            })
            downloadFile({url: '/school/schoolInterconnection/export', form: obj}, () => {
                this.$message.success('导出成功')
            }, () => {

            })
        },
        handleUpload(data) {
            if (data.code === '200') {
                this.$message.success('导入成功')
                this.getList()

            } else {
                this.$confirm(data.msg, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    downloadFile({
                        url: '/parent/schoolInterconnection/errorExport', form: {paramData: data.info.paramData}
                    }, () => {
                        this.$message.success('下载成功')
                    }, () => {

                    })
                }).catch(() => {
                })
            }
        },
        treeClear(data) {
            this.listQuery.classesId = ''
        },
        filterTreeClick(data) {
            if (data.data.organType === '4') {
                this.listQuery.classesId = data.data.id
                data.dt.value = data.data.name
                data.selRef.blur()
            }
        },
        clickBtn(type) {
            switch (type.item.fn) {
                case 'primary': // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    })
                    this.getList(1);
                    break;
                case 'reset': // 重置
                    this.formData.data.forEach((item) => {
                        item.value = '';
                        this.listQuery[item.key] = ''
                    })
                    this.listQuery.classesId = ''
                    this.getList(1);
                    break;
                // case 'add': // 添加
                //     this.handleAdd()
                //     break;
            }
        },
        edit(data) {
            //判断是否为接送人，是接送人编辑时，身份证必填，否则非必填
            if(data.status === '1') {
                this.status = false
            }else{
                this.status = true
            }
			let dataList = this.parentsPhone;
            this.dialogObj.title = "编辑";
            Object.keys(this.ruleForm).forEach((key) => {
                this.ruleForm[key] = data[key]
            })
			console.log(dataList,data,)
			for(let j = 0; j < dataList.length; j++) {
				if(data.id === dataList[j].id) {
					this.ruleForm.phone = dataList[j].parentPhone
				}
			}
            // this.ruleForm.phone = data.parentPhone
            this.ruleForm.schoolId = this.schoolId
            this.dialogObj.dialogVisible = true;
        },
        del(data) {
            this.$confirm('此操作将永久删除，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    parentInfoIds: [data.id]
                }
                this._fet('/parent/schoolInterconnection/delBatch', params).then((res) => {
                    if (res.data.code === '200') {
                        this.$message.success('删除成功')
                        this.getList()
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {
            });
        },
        handleSwitch(data) {
            let campusSafety = new CampusSafetyModel()
            campusSafety.isPickUp({
                id: data.id,
                pickUp: data.status === '1' ? '1' : '2'
            }).then((res) => {
                if (res.data.code === '200') {
                    this.$message.success('修改成功')
                } else {
                    this.$message.error(res.data.msg)
                }
                this.getList();

            })
        },
        handleClose() {
            this.dialogObj.dialogVisible = false;
            this.$refs.ruleForm.resetFields()
            Object.keys(this.ruleForm).forEach((key) => {
                this.ruleForm[key] = ''
            })
            this.ruleForm.schoolId = this.schoolId
        },
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let campusSafety = new CampusSafetyModel()
                    campusSafety.saveParentDirectory(this.ruleForm).then((res) => {
                        this.handleRes(res, () => {
                            this.$message.success(`${this.dialogObj.title}成功`)
                            this.getList();

                            this.handleClose();
                        })
                    })
                }
            });
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
        // 表头颜色
        initTabColor() {
            this.template_info = this.templateInfo;
            this.template_info.forEach((p, i) => {
                if (p.sel) {
                    this.headerColor = p.textColor;
                }
            })
        },
    },
    watch: {
        'templateInfo': {
            handler() {
                this.initTabColor();
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    text-align: left;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 0px 4px 4px 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}
.tac {
    text-align: right;
}

.operation-btn-view {
    box-sizing: border-box;
    background-color: #ffffff;
    margin-top: 10px;
    height: 40px;
    padding: 0 30px 0 8px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.export {
    width: 12px;
    height: 12px;
    margin-right: 8px;
}
.center-main{
    padding: 0;
}
.right-panel {
    // padding-right: 10px;

    .el-icon-question {
        &:hover{
            color: #5cb6ff;
            cursor: pointer;
        }
    }
}
.el-dropdown-menu__item {
    &:hover {
        background-color: #fff;
    }
}
</style>
